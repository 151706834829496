// src/components/Bot.js
import React, { useEffect, useRef } from "react";

const Bot = () => {
  useEffect(() => {
    // 在页面加载时插入SDK脚本
    const sdkScript = document.createElement("script");
    sdkScript.src =
      "https://sf-cdn.coze.com/obj/unpkg-va/flow-platform/chat-app-sdk/0.1.0-beta.2/libs/oversea/index.js";
    sdkScript.onload = () => {
      if (window.CozeWebSDK) {
        new window.CozeWebSDK.WebChatClient({
          config: {
            bot_id: "7380353236236189703",
          },
          componentProps: {
            title: "URL Slug Master Bot",
            icon: "https://github.com/chatgptnexus/chatgptnexus.github.io/assets/156575346/6726b481-9d79-45cc-99f4-df9b1507a9ad",
          },
        });

        setTimeout(() => {
          const languageButtons = document.querySelectorAll(".data-language"); // 假设语种选择按钮都有 data-language 属性
          if (languageButtons.length > 0) {
            const lastButton = languageButtons[languageButtons.length - 1];
            const botElement = document.querySelector(
              'div[style*="position: fixed"]'
            );
            if (botElement) {
              const buttonRect = lastButton.getBoundingClientRect();
              const botWidth = botElement.offsetWidth;
              const botHeight = botElement.offsetHeight;
              const centerX =
                buttonRect.left + buttonRect.width / 2 - botWidth / 2;
              const centerY =
                buttonRect.bottom + 20 + buttonRect.height / 2 - botHeight / 2;
              botElement.style.top = centerY + "px"; // 居中垂直位置
              botElement.style.left = centerX + "px"; // 居中水平位置
              // open automatically
              botElement.click()
            }
          }
        }, 2000); // 2秒后执行，确保bot加载完成
      }
    };
    document.body.appendChild(sdkScript);

    const clarityScript = document.createElement("script");
    clarityScript.type = "text/javascript";
    clarityScript.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "mljyy6ejqe");
    `;
    document.body.appendChild(clarityScript);
    // Cleanup: remove the script when the component unmounts
    return () => {
      document.body.removeChild(sdkScript);
      document.body.removeChild(clarityScript);
    };
  }, []);

  return <div id="bot-container">{/* 这里是你的bot的内容 */}</div>;
};

export default Bot;
